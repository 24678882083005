"use client";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { useMagic } from "@/context/MagicProvider";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import {
  BellRing,
  Gamepad2,
  Settings,
  Sword,
  ThumbsUp,
  Wallet2,
} from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import { useContactModalStore } from "../../ContactForm";
import { useWalletModalStore } from "@/components/magic/auth/RainbowKitWalletModal";

type Props = {
  avatar?: any;
  fallbackAvatar?: string;
  walletAddress?: any;
  unreadNotificationsCount?: number;
};

export default function ClientAvatar({
  avatar,
  fallbackAvatar,
  walletAddress,
  unreadNotificationsCount,
}: Props) {
  const { magic } = useMagic();

  // RainbowKit
  const { openAccountModal } = useAccountModal();
  const { setOpenContact } = useContactModalStore();

  useEffect(() => {
    const avatarLocalStorage = localStorage.getItem("avatar");
    if (!avatarLocalStorage && avatar && avatar.length > 0) {
      localStorage.setItem("avatar", avatar);
    }
  }, [avatar]);

  const { open, setOpen } = useWalletModalStore();

  async function openWallet() {
    if (openAccountModal) {
      // openAccountModal();
      setOpen(true);
    } else {
      await magic?.wallet.showUI();
    }
  }

  return (
    <>
      <Menubar>
        <MenubarMenu>
          <MenubarTrigger>
            <div className="relative">
              <Image
                src={avatar ? avatar : fallbackAvatar}
                width={100}
                height={100}
                alt="User avatar"
                className=" hover:scale-110 mx-auto w-[46px] h-[46px] min-w-[46px] rounded-full 
object-cover shadow-xl shadow-black/30 transition cursor-pointer "
              />
              {unreadNotificationsCount && unreadNotificationsCount > 0 ? (
                <div
                  className="absolute -top-1 -right-2 w-5 h-5 bg-gradient-to-br from-pink via-pink to-red-pink 
            text-white text-xs rounded-full flex items-center justify-center"
                >
                  {unreadNotificationsCount}
                </div>
              ) : null}
            </div>
          </MenubarTrigger>
          <MenubarContent className="">
            <MenubarItem asChild className="w-full cursor-pointer">
              <Link
                href={`/profile/${walletAddress}`}
                className="flex items-center gap-x-2"
              >
                <Sword className="w-4" /> My Inventory
              </Link>
            </MenubarItem>
            <MenubarItem asChild className="w-full cursor-pointer">
              <button
                onClick={openWallet}
                aria-label="Open wallet"
                className="flex items-center gap-x-2"
              >
                <Wallet2 className="w-4" /> My Wallet
              </button>
            </MenubarItem>
            <MenubarItem asChild className="w-full cursor-pointer">
              <Link href="/games" className="flex  items-center gap-x-2">
                <Gamepad2 className="w-4" />
                Play Games
              </Link>
            </MenubarItem>
            <MenubarSeparator />
            <MenubarItem asChild className="w-full cursor-pointer">
              <Link href="/settings" className="flex items-center gap-x-2">
                <Settings className="w-4" /> Settings
              </Link>
              {/* <MenubarShortcut>⌘T</MenubarShortcut> */}
            </MenubarItem>
            <MenubarItem asChild className="w-full cursor-pointer">
              <Link href="/notifications" className="flex items-center gap-x-2">
                <BellRing className="w-4" /> Notifications
                {unreadNotificationsCount && unreadNotificationsCount > 0 ? (
                  <div className="ml-8 w-3 h-3 bg-pink rounded-full flex items-center justify-center" />
                ) : null}
              </Link>
            </MenubarItem>
            <MenubarItem asChild className="w-full cursor-pointer">
              <button
                onClick={() => setOpenContact(true)}
                aria-label="Give feedback"
                className="flex items-center gap-x-2 "
              >
                <ThumbsUp className="w-4" /> Give Feedback
              </button>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </>
  );
}
